export const ProfileIcon = props => (
  <svg
    width={32}
    height={32}
    viewBox='0 0 32 32'
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    {...props}
  >
    <path
      stroke='currentColor'
      strokeWidth='1.7'
      d='M16 16a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z'
    />
    <path
      stroke='currentColor'
      strokeWidth='1.7'
      d='M16 29.333c7.364 0 13.333-5.97 13.333-13.333 0-7.364-5.97-13.333-13.333-13.333C8.636 2.667 2.667 8.637 2.667 16c0 7.364 5.97 13.333 13.333 13.333Z'
    />
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeWidth='1.7'
      d='M23.959 26.667C23.747 22.81 22.566 20 15.999 20c-6.565 0-7.746 2.811-7.958 6.667'
    />
  </svg>
);
