import React from 'react';

export const CloseLargeIcon = props => (
  <svg
    width={32}
    height={32}
    viewBox='0 0 32 32'
    xmlns='http://www.w3.org/2000/svg'
    fill='currentColor'
    {...props}
  >
    <path
      fill='currentColor'
      d='M23.884 6.706 16 14.59 8.116 6.706a.998.998 0 0 0-1.41 1.41L14.586 16l-7.882 7.882a.997.997 0 1 0 1.41 1.412L16 17.412l7.884 7.884a.998.998 0 0 0 1.41-1.41l-7.884-7.884 7.884-7.886a.997.997 0 1 0-1.41-1.412v.002Z'
    />
  </svg>
);
