export const MenuIcon = props => (
  <svg
    width={32}
    height={32}
    viewBox='0 0 32 32'
    xmlns='http://www.w3.org/2000/svg'
    fill='currentColor'
    {...props}
  >
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2.25'
      d='M5.333 9.333h21.334M5.333 16h21.334M5.333 22.667h21.334'
    />
  </svg>
);
