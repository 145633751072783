import {Drawer} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import {ProfileInfo} from 'src/views/HeaderNext/components/ProfileInfo';
import {ProfileMenu} from 'src/views/HeaderNext/components/ProfileMenu';

const useStyles = makeStyles(theme => ({
  modal: {marginTop: 68},
  drawerOpen: {
    width: '100%',
    marginTop: 68,
    paddingTop: theme.spacing(2),
    backgroundColor: theme.palette.colors.drawer,
    height: 'auto',
    borderTop: `0.5px solid ${theme.palette.secondary.main}`
  },
  backdrop: {
    top: 68
  }
}));

export const RightMenuDrawer = ({open, onClose, customer}) => {
  const classes = useStyles();

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={onClose}
      classes={{
        modal: classes.modal,
        paper: classes.drawerOpen
      }}
      BackdropProps={{classes: {root: classes.backdrop}}}
    >
      <div>
        <ProfileInfo isMobile customer={customer} />
        <div style={{padding: '8px 0'}}>
          <ProfileMenu isMobile customer={customer} onCloseDrawer={onClose} />
        </div>
      </div>
    </Drawer>
  );
};
