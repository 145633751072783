import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  divider: {
    position: 'relative',
    width: '100%',
    height: 1,

    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: 0.5,
      backgroundColor: theme.palette.colors.dividerGrey
    }
  }
}));

export const Divider = () => {
  const classes = useStyles();

  return <div className={classes.divider} />;
};
